<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterFeUserBillingAddresses">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('feUser.id')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.name" id="filter_name" :label="$t('feUser.name')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.surname" id="filter_surname" :label="$t('feUser.surname')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.street" id="filter_street" :label="$t('feUser.street')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.city" id="filter_lastName" :label="$t('feUser.city')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.aboCountryExpanded"
                :options="aboCountries"
                :label="$t('feUser.aboCountry')"
                id="filter_aboCountryExpanded"
                optionTitle="name"
              >
              </app-select>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.phone" id="filter_phone" :label="$t('feUser.phone')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.feUser_email" id="filter_feUser_email"
                         :label="$t('feUser.user_email')"></app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('feUser.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="feUserBillingAddress_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('feUser.filter.reset_filter') }}
              </button>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.type"
                :options="valueObjectAddressType"
                :label="$t('feUser.addressType')"
                id="filter_type"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.company" id="filter_company" :label="$t('feUser.company')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.ico" id="filter_ico" :label="$t('feUser.ico')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.dic" id="filter_dic" :label="$t('feUser.dic')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.icDph" id="filter_icDph" :label="$t('feUser.icDph')"></app-input>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../../form/inputs/Input'
import Select from '../../form/select/Select'
import FeUserBillingAddressFilterModel from '../../../model/feUser/FeUserBillingAddressFilter'
import ValueObjectService from '../../../services/ValueObjectService'

export default {
  name: 'FeUserBillingAddressFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(FeUserBillingAddressFilterModel)
    }
  },
  computed: {
    aboCountries () {
      return this.$store.getters['aboCountry/list']
    },
    valueObjectAddressType () {
      return ValueObjectService.getAsArray(this.$store.getters['feUserBillingAddress/valueObjectAddressType'])
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterFeUserBillingAddresses () {
      this.$store.commit('feUserBillingAddress/setPage', 1)
      this.$store.commit('feUserBillingAddress/setFilter', this.filter)
      this.$store.dispatch('feUserBillingAddress/fetch')
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    resetFilters () {
      this.filter = this._.cloneDeep(FeUserBillingAddressFilterModel)
      this.filterFeUserBillingAddresses()
    },
    findCountry (query) {
      this.$store.dispatch('aboCountry/fetch', query)
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['feUserBillingAddress/filter']
  }
}
</script>

<style lang="scss">

</style>
