import Store from '../../store'

const getCountryNameById = (id) => {
  let countryName = id
  Store.getters['aboCountry/list'].forEach(item => {
    if (item.id === id) {
      countryName = item.name
    }
  })
  return countryName
}

export default {
  getCountryNameById
}
