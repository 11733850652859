import Store from '../../store'
import ValueObjectService from '../ValueObjectService'

const getPrettyName = (value) => {
  return ValueObjectService.getPrettyName(
    Store.getters['feUserBillingAddress/valueObjectAddressType'],
    value
  )
}

export default {
  getPrettyName
}
